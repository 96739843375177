import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import {
  BrowserRouter as Router, 
  Link, 
  Route} from 'react-router-dom';

var style = {
  maxWidth: 700,
  margin: "auto",
  textAlign: "center",
  color: "white",
  fontFamily: 'Righteous-Regular, serif',
}

var phantom = {
  display: 'block',
  padding: '20px',
  height: '60px',
  width: '100%',
}

const Footer = () => (
  <>
    {/* <div style={phantom}/> */}
    <div style={style}>
      <Typography variant="caption" align={"center"}>
        © 2020 Humpback Company LLC
      </Typography>
      <Divider style={{ margin: "24px auto", width: 60 }} />
      <Grid container justify={"center"} spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Link to="/about">
            <Typography style={style} align={"center"} gutterBottom color={"white"}>
              About
            </Typography>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Link to="/privacy-policies/web">
            <Typography style={style} align={"center"} gutterBottom color={"white"}>
              Website Privacy Policy
            </Typography>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Link to="/terms-conditions/web">
            <Typography style={style} align={"center"} gutterBottom color={"white"}>
              Website Terms & Conditions
            </Typography>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Link to="/contact-us">
            <Typography style={style} align={"center"} gutterBottom color={"white"}>
              Contact us
            </Typography>
          </Link>
        </Grid>

        {/* ROW 2 */}

        <Grid item xs={12} sm={6} md={3}>
          {/* Under About */}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Link to="/privacy-policies/pocket-eggy">
            <Typography style={style} align={"center"} gutterBottom color={"white"}>
              Pocket Eggy Privacy Policy
            </Typography>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Link to="/terms-conditions/pocket-eggy">
            <Typography style={style} align={"center"} gutterBottom color={"white"}>
              Pocket Eggy Terms & Conditions
            </Typography>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {/* Contact Column */}
        </Grid>

        {/* ROW 3 */}

        <Grid item xs={12} sm={6} md={3}>
          {/* ABOUT COLUMN */}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {/* Contact Column */}
        </Grid>
      </Grid>
    </div>
  </>
);

Footer.propTypes = {};
Footer.defaultProps = {};

export default Footer;
