import React, {Component} from 'react';
import createClass from 'create-react-class';

function Devlog ({ match }) {
  return (
      <h1>
      <center>
          <p2>
              <br></br>
              <br></br>
          Humpback Company Devlog
          </p2>
      </center>
      <br></br>
      <br></br>
      <center>
          <p2>
          </p2>
      </center>
      </h1>
  )
}

export default Devlog;