import React, {Component} from 'react';
import createClass from 'create-react-class';
import Tabbing from '../Components/Tabbing';

function About ({ match }) {
    return (
        <Tabbing />
    )
}

export default About;