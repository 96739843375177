import React, { Component } from 'react';

export default class Contact extends Component {
  render() {
    return (
      <div className="container">
        <p1>
                Contact 
                <a href="mailto:austin@humpbackcompany.com"> Austin </a>   
                at Humpback Company with any questions.
        </p1>  
      </div>
    )
  }
}