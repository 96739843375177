import React, {Component} from 'react';
import {
    BrowserRouter as Router, 
    Link, 
    Route} from 'react-router-dom';
import './app.css';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavItem from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Tabbing from './Components/Tabbing';
import Main from './Views/MainPage';
import About from './Views/About';
import NavbarBrand from 'react-bootstrap/NavbarBrand';
import Devlog from './Views/Devlog';
import MobileApps from './MobileApps';
import PocketEggyPrivacyPolicy from './Views/PocketEggyPrivacyPolicy';
import Footer from './Components/footer';
import CookieConsent from 'react-cookie-consent';

// Policies
import PP from './Views/PrivacyPolicies';
import TC from './Views/TermsConditions';
import PPWeb from './Views/WebPrivacyPolicy';
import TCWeb from './Views/WebTermsConditions';
import PPEggy from './Views/PocketEggyPrivacyPolicy';
import TCEggy from './Views/PocketEggyTermsConditions';
import Contact from './Views/contact';

const injectGA = () => {
  if (typeof window == 'undefined') {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());

  gtag('config', 'UA-133675835-1');
};
  
  function Home () {
    return (
      <>
        <h1>
            <br></br>
            <br></br>
            <br></br>

            <center>
                <p2>
                Humpback Company
                </p2>
                <br></br>
            </center>
        </h1>
        <h2>
            <center>
                <p1>
                Checkout Our Games
                </p1>
            </center>
        </h2>
        <h3>
          <center>
              <MobileApps></MobileApps>
          </center>
        </h3>
      </>
    )
  }
  
  class App extends Component {
    render() {
      let width = window.innerWidth;
      let humpbackString = "Humpback Company";
      if (width < 768) {
        humpbackString = "HC";
      } else {
        humpbackString = "Humpback Company";
      }

      return (
        <Router>
            <>
                <header>
                  {/* Global site tag (gtag.js) - Google Analytics */}
                  <script
                    async
                    src="https://www.googletagmanager.com/gtag/js?id=UA-133675835-1"
                  />
                  <script>{injectGA()}</script>
                </header>
                <Navbar bg="dark" expand="lg">
                    <Navbar.Brand href="/">
                    <img
                      alt=""
                      src={require('./WhaleLogo-Official3.png')}
                      width="60"
                      height="60"
                      className="d-inline-block align-center"
                    />
                      {humpbackString}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        
                        <Nav className="navClass" align="center">
                            <Link to="/"><Nav.Link href="/">Home</Nav.Link></Link>
                            <Link to="/about"><Nav.Link href="/topics">About</Nav.Link></Link>
                            <Link to="/devlog"><Nav.Link href="/devlog">Devlog</Nav.Link></Link>
                        </Nav>

                    </Navbar.Collapse>
                </Navbar>
    
                <Route exact path='/' component={Home} />
                <Route path='/about' component={About} />
                <Route path='/devlog' component={Devlog} />
                <Route path='/privacy-policies' component={PP}/>
                <Route path='/terms-conditions' component={TC}/>
                <Route path='/privacy-policies/web' component={PPWeb}/>
                <Route path='/terms-conditions/web' component={TCWeb}/>
                <Route path='/privacy-policies/pocket-eggy' component={PPEggy}/>
                <Route path='/terms-conditions/pocket-eggy' component={TCEggy}/>
                <Route path='/contact-us' component={Contact}/>
                <footer>
                  <Footer />
                </footer>
                <CookieConsent
                  location="bottom"
                  buttonText="Ok"
                  cookieName="CookieConsenter"
                  style={{ background: "#2B373B" }}
                  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                  expires={150}
                >
                  This website uses cookies to enhance the user experience.{" "}
                  <span style={{ fontSize: "10px" }}>By using our site, you acknowledge that you have read and understand our Web Privacy Policy and our Terms & Conditions.</span>
                </CookieConsent>
            </>
        </Router>
      )
    }
  }
  
  export default App