import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Grid, Card, CardMedia, CardActionArea, CardContent} from '@material-ui/core';
import Container from 'react-bootstrap/Container';

import pocketEggyLogo from "./logo/pocketeggy.png";

const styles = {
  stretch: {
    width: '400px',
    height: '400px',
    resizeMode: 'stretch',
    backgroundImage:  'url('+pocketEggyLogo+')',
  },
  card: {
    maxWidth: 300
  },
  bgImage: {
    height: 150,
    width: 600,
    backgroundPosition: 'left',
    backgroundRepeat: 'no-repeat'
  },
  cardText: {
    color: 'rgb(255, 255, 255)',
    fontSize: '25px',
    fontFamily: 'Righteous-Regular, serif',
    fontStyle: 'normal',
    fontVariant: 'normal',
    backgroundColor: '#f79a7a',
  },
  storeButton: {
    backgroundColor: 'black',
    borderColor: 'black',
  },
  logoImage: {
    width: '50%',
    resizeMode: 'stretch',
  },
  storeImage: {
    width: '100%',
    resizeMode: 'stretch',
  }
};

export default class MobileApps extends React.Component {

  onClickAppStore() {
    console.log("Going to the App Store");
    window.location.assign("https://apps.apple.com/gb/app/id1468767541")
  }

  onClickGooglePlay() {
    console.log("Going to the Google Play Store...")
    window.location.assign("https://play.google.com/store/apps/details?id=com.humpbackco.eggy&hl=en_US")
  }

  render() {
    return (
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item xs={12} sm={12}>
            <Image
              style={styles.logoImage}
              src={require('./logo/pocketeggy.png')} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <button style={styles.storeButton}>
              <img
                style={styles.storeImage}
                src={require("./logo/appstore.png")}
                alt="App Store"
                onClick={this.onClickAppStore} />
            </button>
          </Grid>
          <Grid item xs={12} sm={12}>
            <button style={styles.storeButton}>
              <img
                style={styles.storeImage}
                src={require("./logo/google.png")}
                alt="Google Play Store"
                onClick={this.onClickGooglePlay} />
            </button>
          </Grid>
        </Grid>
    )
  }
}
